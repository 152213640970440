<template>
  <div>
    <template v-if="this.$route.name !== 'tournament' && this.$route.name !== 'playerprofile'">
      <b-container>
        <template v-if="this.$route.name !== 'destinations'">
          <h3 id="title" v-if="this.develop === 'dest'" class="ATD">
            <b-nav-item class="destLink" :to="{name: 'destinations'}"
              >Asian Tour Destinations</b-nav-item
            >
          </h3>

          <b-row class="equalPad">
            <b-col
              class="col-lg-4 col-md-6 col-12 newsDesktop"
              :key="index"
              v-for="(postCard, index) in filterPosts.slice(1, 4)"
            > 
              <div class="outerImage">
                <div class="containedBlock">
                  <img class="newsImage" :src="postCard.better_featured_image.source_url">
                  <div class="gradHome"></div>
                </div>
              </div>
              <div class="newsTitle">News </div>
              <b-link :to="'/' + postCard.slug" class="newsLink">
                <div class="newsHeading" v-html="postCard.title.rendered"></div>
              </b-link>
              <div class="newsContent" v-html="postCard.excerpt.rendered"></div>
            </b-col>
          </b-row>
          <b-row class="hrLines" v-if="featuredTrue != 'Y'">
            <b-col>
              <div class="leftLine"></div>
            </b-col>
            <b-col>
              <div class="middleLine"></div>
            </b-col>
            <b-col>
              <div class="rightLine"></div>
            </b-col>
          </b-row>
          <b-row class="equalPad TopPadPost" v-if="featuredTrue != 'Y'">
            <b-col
              id="ThreeNewsPics"
              class="col-lg-4 col-md-4 col-12 newsDesktop"
              :key="index"
              v-for="(postCard, index) in filterPosts.slice(4,7)"
            > 
              <div class="outerImage">
                <div class="containedBlock">
                  <img class="newsImage" :src="postCard.better_featured_image.source_url">
                  <div class="gradHome"></div>
                </div>
              </div>
              <div class="newsTitle">News</div>
              <b-link :to="'/' + postCard.slug" class="newsLink">
                <div class="newsHeading" v-html="postCard.title.rendered"></div>
              </b-link>
              <div class="newsContent" v-html="postCard.excerpt.rendered"></div>
            </b-col>
          </b-row>
        </template>
      </b-container>
      <template v-if="this.$route.name === 'destinations'">
        <b-row class="equalPad">
          <b-col
            class="col-lg-4 col-md-6 col-12 newsDesktop"
            :key="index"
            v-for="(postCard, index) in filterPosts.slice(1, 4)"
          > 
            <div class="outerImage">
              <div class="containedBlock">
                <img class="newsImage" :src="postCard.better_featured_image.source_url">
                <div class="gradHomeDest"></div>
              </div>
            </div>
            <div class="newsTitle DestTitle">News </div>
            <b-link :to="'/' + postCard.slug" class="newsLink">
              <div class="newsHeading" v-html="postCard.title.rendered"></div>
            </b-link>
            <div class="newsContent" v-html="postCard.excerpt.rendered"></div>
          </b-col>
        </b-row>
      </template>
    </template>
    <template v-else>

      <b-carousel
        id="carousel-1"
        v-model="slide"
        img-width="1024"
        img-height="420"
        controls
        indicators
      >
      <!-- 0-3 -->
        <b-carousel-slide
          v-if="count > 0"
          img-blank
          img-src="https://picsum.photos/1024/480/?image=52"
        >
          <b-row class="equalPad">
            <b-col
              class="col-lg-4 col-md-6 col-12 newsDesktop"
              :key="index"
              v-for="(postCard, index) in allposts.slice(0, 3)"
            > 
              <div class="outerImageTourn">
                <div class="containedBlock">
                  <img class="newsImageTourn" :src="postCard.better_featured_image.source_url">
                  <div class="gradHomeTourn"></div>
                </div>
              </div>
              <div class="newsTitle">News </div>
                <!-- :to="{
                  name: 'test',
                  query: {
                    url: postCard.slug,
                    code: tag,
                  },
                }"   -->
              <b-link class="newsLink"
                :to=" '/' + postCard.slug + '?id=' + course_code + '?season=' + Year"
              
              >
                <div class="newsHeading" v-html="postCard.title.rendered"></div>
              </b-link>
              <div class="newsContentTourn" v-html="postCard.excerpt.rendered"></div>
            </b-col>
          </b-row>
        </b-carousel-slide>
      <!-- 3-6 -->
        <b-carousel-slide
          v-if="count > 3"
          img-blank
          img-src="https://picsum.photos/1024/480/?image=52"
        >
          <b-row class="equalPad">
            <b-col
              class="col-lg-4 col-md-6 col-12 newsDesktop"
              :key="index"
              v-for="(postCard, index) in allposts.slice(3, 6)"
            > 
              <div class="outerImageTourn">
                <div class="containedBlock">
                  <img class="newsImageTourn" :src="postCard.better_featured_image.source_url">
                  <div class="gradHomeTourn"></div>
                </div>
              </div>
              <div class="newsTitle">News </div>
                <!-- :to="{
                  name: 'test',
                  query: {
                    url: postCard.slug,
                    code: tag,
                  },
                }"   -->
              <b-link class="newsLink"
                :to=" '/' + postCard.slug + '?id=' + course_code + '?season=' + Year"
              
              >
                <div class="newsHeading" v-html="postCard.title.rendered"></div>
              </b-link>
              <div class="newsContentTourn" v-html="postCard.excerpt.rendered"></div>
            </b-col>
          </b-row>
        </b-carousel-slide>
      <!-- 6-9 -->
        <b-carousel-slide
          v-if="count > 6"
          img-blank
          img-src="https://picsum.photos/1024/480/?image=52"
        >
          <b-row class="equalPad">
            <b-col
              class="col-lg-4 col-md-6 col-12 newsDesktop"
              :key="index"
              v-for="(postCard, index) in allposts.slice(6, 9)"
            > 
              <div class="outerImageTourn">
                <div class="containedBlock">
                  <img class="newsImageTourn" :src="postCard.better_featured_image.source_url">
                  <div class="gradHomeTourn"></div>
                </div>
              </div>
              <div class="newsTitle">News </div>
                <!-- :to="{
                  name: 'test',
                  query: {
                    url: postCard.slug,
                    code: tag,
                  },
                }"   -->
              <b-link class="newsLink"
                :to=" '/' + postCard.slug + '?id=' + course_code + '?season=' + Year"
              
              >
                <div class="newsHeading" v-html="postCard.title.rendered"></div>
              </b-link>
              <div class="newsContentTourn" v-html="postCard.excerpt.rendered"></div>
            </b-col>
          </b-row>
        </b-carousel-slide>
      </b-carousel>
    </template>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "posts",
  props: ["posts", "develop", "title", "config", 'tag', "course_code", "Year", 'count', 'featuredTrue'],
  data() {
    return {
      allposts: [],
      postsRow: "posts",
      postImg: "postImg",
    };
  },
  methods: {
    tournamentNews: function () {
      axios //add API Call
        .get(process.env.VUE_APP_WP_ASIAN_TOUR_API_URL+"wp/v2/posts?tags=" + this.tag)
        .then((response) => (this.allposts = response.data));
    }
  },
  computed: {
    filterPosts: function() {
      if (this.develop === "dest") return this.allposts;
      else if (this.featuredTrue === "Y")
        return this.allposts.filter((posts) => !posts.categories.indexOf(12));
      
      else return this.allposts.filter((posts) => !posts.categories.indexOf(1));
    },
    // tourOrHome(url) {
    //   console.log('inPost')
    //   if (this.$route.name === "tournament")
    //     url =
    //       process.env.VUE_APP_WP_ASIAN_TOUR_API_URL+"wp/v2/posts?tags=" + this.tag;
    //     return url;
    // }
  },
  mounted() {
    this.tournamentNews(),
    axios //add API Call
      .get(this.posts)
      .then((response) => (
        this.allposts = response.data
      ));
  },
};
</script>

<style scoped>
::v-deep .carousel-control-prev {
  left: -125px;
}
::v-deep .carousel-control-next {
  right: -125px;
}
::v-deep .carousel-control-next-icon {
  background-image: url('https://assets.asiantour.com/asian-tour/2024/03/Icon-awesome-chevron-down.png');
  height: 15px;
  width: 10px;
  margin-top: -60px;
}
::v-deep .carousel-control-prev-icon {
  background-image: url('https://assets.asiantour.com/asian-tour/2024/03/Icon-awesome-chevron-down-1.png');
  height: 15px;
  width: 10px;
  margin-top: -60px;
}
.gradHomeTourn {
  background-color: #5897E6;
  background-image: linear-gradient(to right, #5897E6, #A8B7C9);
  height: 10px;
  width: 96%;
  position: absolute;
  top: 230px;
}
::v-deep .carousel-caption {
  position: absolute;
  right: 0%;
  bottom: 26px;
  left: 0%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}
.TopPadPost {
  padding-top: 20px;
}
::v-deep .gradHome {
  background-color: #5897E6;
  background-image: linear-gradient(to right, #5897E6 , #A8B7C9);
  height: 10px;
  width: 96%;
  position: absolute;
  /* top: 190px; */
  top: 220px;
}
::v-deep .gradHomeDest {
  background-color: #98C556;
  background-image: linear-gradient(to right, #98C556 , #BCDD8D);
  height: 10px;
  width: 96%;
  position: absolute;
  top: 220px;
}
.newsLink:hover {
  text-decoration: none;
}
.hrLines {
  margin-top: 20px;
  margin-bottom: 20px;
}
.leftLine {
  height: 1px;
  background-color: #EBEBEB;
  margin-left: 8px;
  margin-right: 3px;
}
.middleLine {
  height: 1px;
  background-color: #EBEBEB;
  margin-left: 3px;
  margin-right: 9px;
}
.rightLine {
  height: 1px;
  background-color: #EBEBEB;
  margin-left: -4px;
  margin-right: 15px;
}
.outerImage {
  /* max-height: 200px; */
  max-height: 230px;
  overflow: hidden;
}
.outerImageTourn {
  max-height: 275px;
  overflow: hidden;
}
.newsImage {
  width: 100%;
  /* max-height: 200px; */
  padding-right: 5px;
}
.newsImageTourn {
  width: 100%;
  max-height: 239px;
  min-height: 240px;
  padding-right: 5px;
  overflow: hidden;
}
.newsTitle {
  color: #A8B7C9;
  font-size: 10px;
  text-transform: uppercase;
  text-align: left;
  padding-top: 10px;
  padding-bottom: 10px;
}
.newsHeading {
  color: #0A3F7F;
  font-size: 19px;
  font-weight: 500;
  margin-bottom: 15px;
  white-space: normal;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-align: left;
}
.newsHeading:hover {
  color: #5897E6;
  font-size: 19px;
  font-weight: 500;
  margin-bottom: 15px;
  white-space: normal;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-align: left;
}
.newsContent {
  color: #707070;
  font-size: 14px;
  font-weight: 300;
}
.newsContentTourn {
  color: #707070;
  font-size: 14px;
  font-weight: 300;
  text-align: left;
}
a.destNews > .pt:hover {
  text-decoration: none;
  color: #5897E6;
}
a.destNews {
  text-decoration: none;
}
.newsTitle {
  color: #A8B7C9;
  font-size: 10px;
  text-transform: uppercase;
  text-align: left;
  padding-top: 10px;
  padding-bottom: 10px;
}
.equalPad {
  padding-left: 10px;
  padding-right: 10px;
}
.apgaHome > .card-body{
  /* min-height: 50px; */
  min-height: 120px;
}
.card-img-top {
  width: 100%;
  height: auto;
  min-height: 183px;
  border-radius: 0px 0px 0 0 !important;
  max-height: 180px;
  object-fit: cover;
}
.card-body {
  padding: 0px;
  min-height: 180px;
  background: #fff;
  border-radius: 0px 0px 10px 10px;
}
.card-footer {
  border-radius: 0 0 10px 10px !important;
  background-color: #133f7b;
  color: white;
  text-align: center;
}
#title {
  padding-top: 5%;
  padding-bottom: 5%;
  color: #133f7b;
}
.card {
  background-color: transparent;
  border: 0;
}
.exre {
  font-size: 14px;
  color: #b4b4b4;
}
.pt {
  color: #133f7a;
  font-size: 16px;
}
::v-deep .nav-link {
  color: white;

  margin-top: -8%;
}
.developGreenText {
  color: #76c3bd !important;
}
.developGreenText > .card-footer {
  background-color: #76c3bd;
}
.developGreenText > .card-body > .exre {
  font-size: 10pt;
}
.developGreenText > .card-body {
  min-height: 200px;
}
.newsLink > a {
  padding: 0;
}
li {
  list-style: none;
}
.destLink > a {
  padding-left: 0;
  margin-top: 0;
  color: #133f7b !important;
}
.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto{
  padding-left: 5px!important;
  padding-right: 5px!important;
}

@media only screen and (max-width: 990px) {
  ::v-deep .outerImage {
    max-height: none!important;
    overflow: hidden;
  }
  ::v-deep .gradHome {
    display: none;
  }
}
@media only screen and (max-width: 768px) {
  .equalPad {
    padding-left: auto;
    padding-right: auto;
  }
  ::v-deep .card {
    padding-bottom: 20px;
  }
  #title {
    padding-bottom: 12%;
  }
  .dLink{
    padding-bottom: 50px;
  }
  .ATD {
    padding-bottom: 8%!important;
  }
  .newsHeading {
    color: #0A3F7F;
    font-size: 19px;
    font-weight: 500;
    margin-bottom: 15px;
    white-space: normal;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-align: left;
  }
  .newsHeading:hover {
    color: #5897E6;
    font-size: 19px;
    font-weight: 500;
    margin-bottom: 15px;
    white-space: normal;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-align: left;
  }
}

@media only screen and (max-width: 550px) {
  .newsImageTourn {
    width: 100%;
    max-height: auto!important;
    min-height: auto !important;
    padding-right: 5px;
    overflow: hidden;
  }
  .hrLines {
    display: none;
  }
  ::v-deep .gradHomeDest {
    background-color: #98C556;
    background-image: linear-gradient(to right, #98C556 , #BCDD8D);
    height: 10px;
    width: 96%;
    position: absolute;
    top: auto;
  }
  .newsImage {
    width: 100%;
    /* max-height: 200px; */
    padding-right: 5px;
    height: 230px;
  }
  .DestTitle {
    padding-top: 22px;
  }
}
</style>
